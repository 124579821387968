html,
body {
  width: 100%;
  height: 100%;
}

.DatePicker {
  width: 100%;
}

div.chakra-tabs__tablist > button:focus {
  box-shadow: none !important;
}
